@import "vars";

/*
 * Basic
 */

html {
    height: 100%;
    margin: 0;
    padding: 0;
    text-align: left;
}
body {
    margin: 0;
    font-family: $Serif;
    height: 100%;
}
a {
    text-decoration: none;
    color: $accent;
}
a:hover {
    text-decoration: underline;
}
hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

h1 {
    font-family: $SansSerif;
}

h2 {
    font-family: $SansSerif;
}

h3 {
    font-family: $SansSerif;
}

ul {
    text-align: left;
}

/*
 * Social
 */

 .social {
    font-size: 120%;
    color: $accent;
    display: inline;
    vertical-align: -10%;
 }

 .social:hover {
     color: $accent;
     text-decoration: none;
 }

/*
 * Navbar
 */

#author-name {
    font-size: 30px;
    color: #272727;
    display: inline-block;
    font-family: $Serif;
}
.navbar {
    padding: 50px 0 50px 0;
}
.navbar-ul {
    display: inline-block;
    margin: 0;
    padding: 5px 0 5px 0;
    margin-left: 20px;
}
.nav-list {
    list-style-type: none;
    margin: 0 5px 0 10px;
}
.alignable {
    display: inline-block;
}
.pull-left {
    @extend .alignable;
    float: left;
}
.pull-right {
    @extend .alignable;
    float: right;
}

/* Divs */

.container {
    max-width: $max-width;
    margin: auto;
}
.wrapper {
    min-height: 100%;
}

/* Institute Image */

#ins-logo {
	margin: auto;
	display: block;
	max-height: 100px;
	text-align: center;
}

/* Mobile */

@media (max-width: 800px) {
    .container {
        max-width: $med-width;
        transition: 0.3s ease all;
    }
}

@media (max-width: 650px) {
    .container {
        max-width: 83%;
    }
    .navbar-ul {
        margin-top: 5px;
    }
}

@media (max-width: 500px) {
	.navbar {
		text-align: center;
		padding: 50px 0px 0px 0px;
	}
	#author-name {
		width: 100%;
		float: none;
	}
	#navlist {
		padding: 10px 0 10px 0;
		margin: 0;
		display: block;
		float: none;
		text-align: center;
		width: 100%;
	}
	#navlist li.alignable {
		display: inline-block;
	}
	#navlist li.pull-left {
		float: none;
	}
	#navlist li.nav-list {
		margin: 0;
	}
}

@import "typography";
@import "tables";
