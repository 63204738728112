/*
 * Customization Variables
 */

$accent: #FF0F00;
$list_border: #dbc3b6;
$image-size: 120px;

$Serif: 'Roboto Slab', serif;
$SansSerif: 'Roboto', sans-serif;
$Monospace: 'Roboto Mono', monospace;

/*
 * Build Variables, don't touch
 */

$max-width: 700px;
$med-width: 600px;
$small-width: 480px;
